import React, {Component, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as ReactBootStrap from 'react-bootstrap';
import Departamentos from '../API/Departamentos';
import '../tools/css/Activar_membresia.css';
import axios from 'axios';
import img from '../tools/images/logoblanco.png';
import icono_aprobado from '../tools/images/aprobado icono.svg'
import icono_rechazada from '../tools/images/rechazado icono.svg'
import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaTextHeight } from 'react-icons/fa';

const ApiURL="https://medicallapi-test.azurewebsites.net/";
const Token =sessionStorage.getItem('token');


var proceso=null;
var estado=null
var mensaje='';

var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var id_membresia = urlParams.get('code');







class Detalle_compra extends Component  { 

  constructor(props)
    {
     super()
      this.state={
        show:false,
        Detalle_orden:[],
        Banco:[],
        Comprador:[],
        loading: false,
        loading_button: true,
        active_button: true,
        loading_button_activacuenta: true,
        button_activacuenta: false,
          form:{   
            cod_membresia1:'',
            cod_membresia2:'',
            cod_membresia3:'',
            cod_membresia4:'',
            cod_membresia5:''
          }
          
        }

    }

 

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
    
    
    }    


    componentDidMount(){

      $('#btn-paginaprincipal').hide();

      if(!sessionStorage.getItem('token')){
        window.location.href="/?code="+id_membresia;
        
      }else{

        this.modal_activamembresia()

      }

      //alert(id_orden)
     
    }  

    modal_activamembresia(){      
      $('#modal-activamembresia').modal('show');
      $("#modal-activamembresia input").val("");
      
      //this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
    }

   

    Activar_membresia=async()=>{

      if(this.state.form.cod_membresia1=='' || this.state.form.cod_membresia2=='' || this.state.form.cod_membresia3=='' || this.state.form.cod_membresia4=='' || this.state.form.cod_membresia5=='' ){
  
        store.addNotification({
          title: 'Atención',
          message: "Ingresa un código de membresía valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
  
      }else{
        
        
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })    
  
        await axios.put(ApiURL+"/api/Memberships/AddToInstitution", 
  
          {           
            membershipCode: this.state.form.cod_membresia1+"-"+this.state.form.cod_membresia2+"-"+this.state.form.cod_membresia3+"-"+this.state.form.cod_membresia4+"-"+this.state.form.cod_membresia5          
          })
   
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true }) 
  
          $('#btn-activacuenta').hide();
          $('#btn-paginaprincipal').show();
          sessionStorage.removeItem('codigo');
          sessionStorage.setItem('codigo','null')
  
          //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
    
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
          //$('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })
      
  
      }      
  
     }


   
 

   render(){  
      const {form} = this.state;

      return(      
        
       
        <div className=""> 

                <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                
               
                 
                      <a id="img_center"  href="https://medicall24.com.co/" className="navbar-brand"> <img  src={img} alt="logo"  width= "230"/> </a>
                  
    
                </nav>

                <div className="modal fade" id="modal-activamembresia" data-backdrop="static">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="col-12 modal-title text-center" id="correo_activacion"><b>Activar membresía</b></h4>
                          
                        </div>
        
                        <div className="modal-body">                       
    
                              <div className="row">                           
                                              
                                  <div className="col-md-12">
                                      <div className="form-group"> 
    
                                              <label >Ingresa el código de la membresía:</label>
                                          
                                      </div>          
                                  </div>
    
                              </div> 
    
                              <div className="row justify-content-md-center"> <br/>                                      
                                  
                                     
                                  <input type="text" className="form-control" maxLength="5" name="cod_membresia1" id="cod_membresia" onChange={this.handleChange}/>_
                                  <input type="text" className="form-control" maxLength="5" name="cod_membresia2" id="cod_membresia" onChange={this.handleChange}/>_
                                  <input type="text" className="form-control" maxLength="5" name="cod_membresia3" id="cod_membresia" onChange={this.handleChange}/>_
                                  <input type="text" className="form-control" maxLength="5" name="cod_membresia4" id="cod_membresia" onChange={this.handleChange}/>_
                                  <input type="text" className="form-control" maxLength="5" name="cod_membresia5" id="cod_membresia" onChange={this.handleChange}/>
                                  
                                 
                  
                              </div>
    
                              <div className="col-md-12 text-center"> <br/>
                                      <button type="submit" id="btn-activacuenta" className="btn"  disabled={!this.state.active_button} onClick={()=> this.Activar_membresia()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar</button>
                                      <a type="submit" id="btn-paginaprincipal" className="btn" href='/inicio' >  Ir a pagina principal</a>                     
                              </div>
        
                                  
                        </div> 
        
                      </div>
                      
                    </div>
                
                </div>
         
               


        </div>

        
         
      )

  }  
  
  
}






export default Detalle_compra;