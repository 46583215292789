import React, {Component} from 'react';
import Select from 'react-select';
import axios from 'axios';

//Componente para listar en select los tipos de servicios que prestará el medico consumido en API

class TipoServicios extends Component {   

    constructor()
    {
     super()
      this.state={ 
        selectOptions : [],       
        servicios: []
      }
    }
      
      async getOptions(){
        const res = await axios.get('https://medicallapi-test.azurewebsites.net//api/TypeServices')
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.name
    
        }))
    
        this.setState({selectOptions: options})
    
      }

      handleChange(e){
        console.log(e)
        this.setState({servicios:e})
      }
    
      componentDidMount(){
          this.getOptions()
      }
      

    render(){ 
        
   
     return (   
     
        <div>
                <Select 
                                                                   
                     options={this.state.selectOptions}
                     onChange={this.handleChange.bind(this)}
                     isMulti
                     id="tipo_servi"
                     className="basic-multi-select"
                     classNamePrefix="select"
                     placeholder="Seleccione..."   
                     name="tipo_service"             
                />                                 
         
        </div>    
      
    )

  }
  
}  


export default TipoServicios;