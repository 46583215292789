import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select de servicios consumido en API

class Servicios_cobro extends Component {   

    constructor()
    {
     super()
      this.state={        
        servicios: [],
        id_servi_cobro: ''
      }
    }

    componentDidMount=async()=>{    
    
      await axios.get("https://medicallapi-test.azurewebsites.net//api/Services/ListActive")
        .then((response) => {
          console.log(response);
          this.setState({servicios: response.data.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }  
      
      handleChange = (e) =>{
        this.setState({id_servi_cobro:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      }      

    render(){ 
      const {handleridservicobro} = this.props  
      const {id_servi_cobro} = this.state  
   
     return (   
     
        <div>

                <select onChange={this.handleChange} onClick={()=>handleridservicobro(id_servi_cobro)} value={this.state.id_servi_cobro} autoComplete='nope' className="form-control" id="servicio_cobro" name="servi">
                    <option  value="0"> Seleccione...</option> ,
                    {this.state.servicios.map(item=>                              
                    <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
} 

Servicios_cobro.propTypes = {
  handleridservicobro:PropTypes.func.isRequired
}


export default Servicios_cobro;