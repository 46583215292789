import React, {Component} from 'react';
import '../tools/css/Home.css';
import '../tools/css/Paginacion.css';
import Eps from '../API/Eps';
import Regimen from '../API/Regimen';
import TipoID from '../API/TipoID';
import BtnEdit from '../components/BtnEdit';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import * as ReactBootStrap from 'react-bootstrap';
import Timer from 'react-compound-timer'
import  * as signalR from '@aspnet/signalr';

import emptypage from '../tools/images/carpeta.gif';

import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import Sound from '../components/Sound';

import Logo_nuevo from '../tools/images/Nuevo';
import Logo_clock from '../tools/images/Clock';
import Logo_aceptar from '../tools/images/Acept';
import Logo_rechaza from '../tools/images/Rejected';
import Buscar from '../tools/images/Buscar';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaFileDownload, FaTextHeight } from 'react-icons/fa';

const ApiURL="https://medicallapi-test.azurewebsites.net/";
const Token =sessionStorage.getItem('token');
var institution_id =sessionStorage.getItem('id_institution');

var tipo_id='null';
var id_entity='0';
var med='null';
var id_entidad=0;
var id_regimen=0;
var id_nivel=0;
var id_tipo_afilia=0;
var mensaje='';
var buscar_pendiente='';
var buscar_aceptada='';
var buscar_rechazada='';


class Home extends Component  { 

  constructor(props)
    {
     super()
      this.state={
        show:false,
        Pendientes: [],
        Pager_Pendientes: [],
        pager_infopendiente:'',
        Aprobadas: [],
        Pager_Aprobadas: [],
        pager_infoaprobada:'',
        Rechazadas: [], 
        Pager_Rechazadas: [],
        Historial_solicitudes:[],
        pager_inforechaza:'',
        acepta:'',
        SignalrMessage:'',
        SignalrConnection: null,
        loading: false,
        loading_button: true,
        active_button: true,
        loading_btnhis: true,
        active_btnhis: true,
        estado_sonido:true,
        currentDateTime: Date().toLocaleString(),
        //sound_notification:false,
          
        form:{   
        id_soli_acep:'',     
        id_pacie_acep:'',
        tipoid_pacie_acep:'',
        nom_pcte_acep:'',
        nom2_pcte_acep:'',
        apell1_pcte_acep:'',
        apell2_pcte_acep:'',
        descrip:'',
        fecha_naci:'',
        dir_pcte:'',
        tel_pcte:'',
        eps_pcte:'',
        regimen_pcte:'',
        responsable_pcte:'',
        telresponsable_pcte:'',
        parentescoresp_pcte:'',
        edad_pcte:'',
        genero_pcte:'',
        departamento_pct:'',
        municipio_pcte:'', 
        id_pacie:'',
        buscar_pendientes:'',
        buscar_aceptadas:'',
        buscar_rechazadas:''   
        
        }
        
      }
    }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
    }



    BuscarPendiente = (bs) =>{
      this.setState({buscar_pendientes:bs.target.value})
      console.log(bs.target.value)
      buscar_pendiente=(bs.target.value)
      this.searchDatapendiente(buscar_pendiente)
     
    }

    BuscarAceptada = (ba) =>{
      this.setState({buscar_pendientes:ba.target.value})
      console.log(ba.target.value)
      buscar_aceptada=(ba.target.value)
      this.searchDataaceptada(buscar_aceptada)
     
    }

    BuscarRechazada = (br) =>{
      this.setState({buscar_rechazadas:br.target.value})
      console.log(br.target.value)
      buscar_rechazada=(br.target.value)
      this.searchDatarechazada(buscar_rechazada)
     
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {

        if(tipo_id=='null' || this.state.form.id_pacie.length==0){

          store.addNotification({
            title: 'Atención',
            message: "Los campos tipo de ID y/o identificación están vacios!!!",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{

          axios.post(ApiURL+"/api/Patients/GetPatient",
  
          {
          identification: this.state.form.id_pacie,
          typeId: tipo_id
          
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  =response.data.id +'-'+ response.data.user.name1 +' '+ response.data.user.name2 +' '+ response.data.user.lastname1 +' '+ response.data.user.lastname2;
          //alert(mensaje);
          this.setState({
            form:{
              id_contra:this.state.form.id_contra,
              fecha_cita:this.state.form.fecha_cita,
              nom_pacien:response.data.user.name1 +' '+ response.data.user.name2 +' '+ response.data.user.lastname1 +' '+ response.data.user.lastname2,
              patientid:response.data.id
             
            }
          })
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
          //alert(this.state.form.patientid)
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Mensaje',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

        }        
        
      }
    }
    

    GetAcepta = (e) =>{
      this.setState({acepta:e.target.value})
      console.log(e.target.value)
      med=(e.target.value)
     
    }  
  
    handleModal()
    {
      
      $('#modal-solicitudes').modal('show'); // <- to show modal   
    }

    handleModaledit()
    {
     
      $('#modal-solicitudesedit').modal('show'); // <- to show modal   
    }

    handleModalrespuesta()
    {     
      med='null'
      $("#acepta").val($('#acepta > option:first').val());
      $('#modal-respuesta').modal('show'); // <- to show modal 
      
    }


    modal_informacionpcte(){
     
      $('#modal-infopcte').modal('show');
     
    }

    handleModalhistorialsoli()
    {     
      $('#modal-historial_solicitudes').modal('show'); // <- to show modal historial de solicitudes 
      
    }

    componentDidMount(){
      $('#tabla1').hide();
      $('#empty_page').hide();
      $('#text_page').hide();     

      this.SignalR();
      this.SolicitudesPendientes(); 
      this.Datos_Institucion();
         
    }

    empty_page_hide(){
      $('#tabla1').hide();
      $('#tabla2').hide();
      $('#tabla3').hide();
      $('#empty_page').hide();
      $('#empty_page2').hide();
      $('#empty_page3').hide();
      $('#text_page').hide();
      $('#text_page2').hide();
      $('#text_page3').hide();
    }


    SignalR(){
      
      const protocol = new signalR.JsonHubProtocol();
      const SignalRconnection = new signalR.HubConnectionBuilder()
      .withUrl(ApiURL+'/api/notifications', 
      {
          accessTokenFactory: () => Token
      }) 
      .withHubProtocol(protocol)    
      .build();
      this.setState({SignalRconnection}, () =>{
        this.state.SignalRconnection.start()
        .then(() =>console.log('Conexion iniciada'))
        .catch(err =>console.log('Error de Conexion signalR'))

        this.state.SignalRconnection.on("ShowNotification", (message) => {
         const SignalrMessage = message.split('-');
         const msg1=SignalrMessage[0];
         const msg2=SignalrMessage[1];
         const num=msg1.split('-').length;

         console.log( SignalrMessage);         
         this.setState({msg1});
         this.setState({msg2});
         this.setState({num});

        
         this.SolicitudesPendientes();
         this.setState({ estado_sonido: this.state.estado_sonido = false })
                  
         store.addNotification({
          title: msg1,
          message: msg2,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })
      });
             
      })
      
    }


    Datos_Institucion(){    
      
      axios.get(ApiURL+"/api/Institutions/GetInstitution/"+institution_id)
      .then((response) => {
        console.log(response);
      

       if(response.data.typeService.consultaUrgencias==false){

        $('#modal-verificaconvenio').modal('show'); // <- to show modal aviso

       }else{

         if(response.data.typeService.consultaUrgencias==true){

          $('#modal-verificaconvenio').modal('hide'); // <- to show modal aviso

         }
       }


        
      })
      .catch((error) => {
        console.log(error);
      })
    }


    SolicitudesPendientes(){
      this.setState({estado_sonido:this.state.estado_sonido = true})
      this.empty_page_hide();
      //this.setState({ sound_notification: this.state.sound_notification = false })
      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/AccessRequests/ListPending")
      .then((response) => {
        console.log(response);


        this.setState({Pendientes: response.data.data})
        this.setState({Pager_Pendientes: response.data.pager.items})
        this.setState({pager_infopendiente: response.data.pager.showData})

        if(this.state.Pendientes.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        //this.setState({ sound_notification: this.state.sound_notification = true })
        this.setState({ loading: this.state.loading = true })
        
      })
      .catch((error) => {
        console.log(error);
      })      
    }


    loadMoreDatapendiente(page_pendien) {
      var page=page_pendien.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/AccessRequests/ListPending?numeropagina="+page)
      .then((response) => {
        console.log(response);
        this.setState({Pendientes: response.data.data})
        this.setState({Pager_Pendientes: response.data.pager.items})
        this.setState({pager_infopendiente: response.data.pager.showData})

       
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }


      searchDatapendiente(buscar_pendiente) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/AccessRequests/ListPending?texto="+buscar_pendiente)
        .then((response) => {

        console.log(response);
        this.setState({Pendientes: response.data.data})
        this.setState({Pager_Pendientes: response.data.pager.items})
        this.setState({pager_infopendiente: response.data.pager.showData})        
  
        
        this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


    SolicitudesAceptadas(){
      this.empty_page_hide();
      this.setState({ loading: this.state.loading = false })

      axios.get(ApiURL+"/api/AccessRequests/ListApproved")
      .then((response) => {
        console.log(response);

        this.setState({Aprobadas: response.data.data})
        this.setState({Pager_Aprobadas: response.data.pager.items})
        this.setState({pager_infoaprobada:response.data.pager.showData})

        if(this.state.Aprobadas.length==0){
          $('#tabla2').hide();
          $('#empty_page2').show();
          $('#text_page2').show();
        }else{
          $('#tabla2').show();
          $('#empty_page2').hide();
          $('#text_page2').hide();
        }
  

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    }


    loadMoreDataaprobada(page_aprobada) {
      var page=page_aprobada.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/AccessRequests/ListApproved?numeropagina="+page)
      .then((response) => {
        console.log(response);
        
        this.setState({Aprobadas: response.data.data})
        this.setState({Pager_Aprobadas: response.data.pager.items})

        this.setState({pager_infoaprobada:response.data.pager.showData})
  
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }


      searchDataaceptada(buscar_aceptada) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/AccessRequests/ListApproved?texto="+buscar_aceptada)
        .then((response) => {

        console.log(response);
        this.setState({Aprobadas: response.data.data})
        this.setState({Pager_Aprobadas: response.data.pager.items})
        this.setState({pager_infoaprobada: response.data.pager.showData})  
        
        this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


    SolicitudesRechazadas(){
      this.empty_page_hide();
      this.setState({ loading: this.state.loading = false })

      axios.get(ApiURL+"/api/AccessRequests/ListRejected")
      .then((response) => {
        console.log(response);
        this.setState({Rechazadas: response.data.data})
        this.setState({Pager_Rechazadas: response.data.pager.items})      
        this.setState({pager_inforechaza:response.data.pager.showData})

        if(this.state.Rechazadas.length==0){
          $('#tabla3').hide();
          $('#empty_page3').show();
          $('#text_page3').show();
        }else{
          $('#tabla3').show();
          $('#empty_page3').hide();
          $('#text_page3').hide();
        }
  
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    }

    loadMoreDatarechazada(page_rechaza) {
      var page=page_rechaza.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/AccessRequests/ListRejected?numeropagina="+page)
      .then((response) => {
        console.log(response);
        this.setState({Rechazadas: response.data.data})
        this.setState({Pager_Rechazadas: response.data.pager.items})
        this.setState({pager_inforechaza: response.data.pager.showData})
  
        console.log(response.data.pager)
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }


      searchDatarechazada(buscar_rechazada) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/AccessRequests/ListRejected?texto="+buscar_rechazada)
        .then((response) => {
          console.log(response);

          this.setState({Rechazadas: response.data.data})
          this.setState({Pager_Rechazadas: response.data.pager.items})
          this.setState({pager_inforechaza: response.data.pager.showData})
    
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }

    Eventaceptar(){

      if(med=='null'){

        store.addNotification({
          title: 'Atención',
          message: "Seleccione una respuesta",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(med=='false'){

          if(this.state.form.descrip.length==0){
            store.addNotification({
              title: 'Atención',
              message: "Por favor ingrese el motivo del rechazo",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
          }else{
  
            this.aceptarsolicitud();
  
          }
  
          
        }else{
  
          this.aceptarsolicitud();
          
        }


      }

      
    }


    aceptarsolicitud=async()=>{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ loading_button: this.state.active_button = false })
      await axios.put(ApiURL+"/api/AccessRequests/Accept",
  
          {accessRequestId: this.state.form.id_soli_acep,
          accepted: med,
          entityId: id_entidad,
          regimeId: id_regimen,
          level:id_nivel,
          typeAffiliateId: id_tipo_afilia,
          annotation:this.state.form.descrip
          } )          
          
        .then(response=>{
          console.log(response)
          //var  dato  = response.status;
          //alert(dato);
          mensaje  = response.data.message;
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);

          //var  dato  = this.state.form.id_soli_acep;
          //alert(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })
          this.SolicitudesPendientes();
          $('#modal-respuesta').modal('hide');

          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })
            this.SolicitudesPendientes();
            
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
  
     }

    
    seleccionarsolicitud=(solicitud)=>{
      id_entidad=solicitud.entityId
      id_regimen=solicitud.regimeId
      id_nivel=solicitud.level
      id_tipo_afilia=solicitud.typeServiceId
      this.setState({
        form:{
          id_soli_acep:solicitud.id,
          id_pacie_acep:solicitud.user.identification,
          tipoid_pacie_acep:solicitud.user.typeId,
          nom_pcte_acep:solicitud.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ solicitud.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ solicitud.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +' '+ solicitud.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          descrip:'',
          fecha_naci:moment(solicitud.user.birthday).format("DD/MM/YYYY"),
          edad_pcte:moment(solicitud.user.birthday).toNow(true),
          genero_pcte:solicitud.user.gender,
          dir_pcte:solicitud.user.address,
          tel_pcte:solicitud.user.phone,
          responsable_pcte:solicitud.user.personInCharge,
          telresponsable_pcte:solicitud.user.phonePerson,
          parentescoresp_pcte:solicitud.user.relationshipPerson,
          municipio_pcte:solicitud.user.municipality.nombre.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          departamento_pct:solicitud.user.department.nombre.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          eps_pcte:solicitud.entity.razonSocial,
          regimen_pcte:solicitud.regime.nombre         
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }


    Historial_Solicitudes=async()=>{

      this.setState({ loading_btnhis: this.state.loading_btnhis = false })
      this.setState({ active_btnhis: this.state.active_btnhis = false })

      await axios.post(ApiURL+"/api/AccessRequests/ListByPatient",
  
          {          
          identification: this.state.form.id_pacie_acep,
          typeId: this.state.form.tipoid_pacie_acep
          } )          
          //this.requierecita()
  
        .then(response=>{
          //console.log(response)  
          
          this.setState({ loading_btnhis: this.state.loading_btnhis = true })
          this.setState({ active_btnhis: this.state.active_btnhis = true })

          this.setState({Historial_solicitudes: response.data})        
         
          this.handleModalhistorialsoli();
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_btnhis: this.state.loading_btnhis = true })
            this.setState({ active_btnhis: this.state.active_btnhis = true })
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_btnhis: this.state.loading_btnhis = true })
            this.setState({ active_btnhis: this.state.active_btnhis = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_btnhis: this.state.loading_btnhis = true })
            this.setState({ active_btnhis: this.state.active_btnhis = true })
        }
        //console.log(error.config);
      
      })
  
     }

    


    handleridtipoid = (t) => {
      console.log(t)
      tipo_id=(t)
      //alert(tipo_id)
    }

    handlerideps = (p) => {
      console.log(p)
      id_entity=(p)
      //alert(id_entity)
    }

 
   render(){  
     const {form} = this.state;
  
      return(   
       
        <div className="content-wrapper">   

          <div className="col-12 col-sm-12">
            
                <div className="row">
                 
          
                    <div className="col-sm-10">
                    <br></br>
                       <div className="text-center" id="title"> <h6> <b> SOLICITUDES DE CONSULTAS POR URGENCIA </b>  </h6> </div>
          
                    </div>                   
            
          
                </div>

                <div className="card card-primary card-outline card-tabs">
                  <div className="card-header p-0 pt-1 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" onClick={()=>{this.SolicitudesPendientes()}}>Solicitudes recibidas <Logo_clock/> </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false" onClick={()=>{this.SolicitudesAceptadas()}}>Solicitudes aceptadas <Logo_aceptar/> </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="custom-tabs-three-messages-tab" data-toggle="pill" href="#custom-tabs-three-messages" role="tab" aria-controls="custom-tabs-three-messages" aria-selected="false" onClick={()=>{this.SolicitudesRechazadas()}}>Solicitudes rechazadas <Logo_rechaza/> </a>
                      </li>
                     
                    </ul>
                  </div>
                  <div className="card-body">
                    <div className="tab-content" id="custom-tabs-three-tabContent">
                      <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                        
                          <div className="d-flex justify-content-center">
                            
                              {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                              {this.state.estado_sonido?false:<Sound/>}
                              
                          </div>
                          
                          <div className="card table-responsive" id="tabla1">                    

                              <div className="row">
                                      
                                <div className="col-sm-3">
                                          <div className="input-group input-group-sm mb-3 mr-sm-2">
                                             <div className="input-group-prepend">
                                               <div className="input-group-text"><Buscar/></div>
                                             </div>
                                             <input type="search" className="form-control" placeholder="Buscar" name="buscar_solicitadas" onChange={this.BuscarPendiente}/>
                                          </div>                                
                                    </div>
                                    
                              </div>
    
                              <table id="tabla_solicitudes" className="table table-bordered table-striped table-hover">
                                <thead>
                                  <tr>
                                    <th>ID Solicitud</th>
                                    <th>Fecha solicitud</th>
                                    <th>Paciente</th>
                                    <th>Identificación</th>
                                    <th>EPS</th>
                                    <th>Regimen</th>
                                    <th>Tiempo de solicitud</th>
                                    
                                  </tr>
                                </thead>
                                
                                <tbody>
                                    
                          
                                    {this.state.Pendientes.map(solicitud=>{
 
                                        var fecha1 = new Date(this.state.currentDateTime)
                                        var fecha2 = new Date(solicitud.date)                                                           
                                        //var dife=fecha1-fecha2
                                        //alert(dife)

                                      return(      
                                      
                                      <tr onClick={()=>{this.handleModalrespuesta();this.seleccionarsolicitud(solicitud)}}>          
                                        <td>{solicitud.id}</td>
                                        <td> 
                                        <Moment format="YYYY/MM/DD HH:mm">
                                        {solicitud.date}
                                        </Moment>
                                        </td>
                                        <td>{(solicitud.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(solicitud.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(solicitud.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(solicitud.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{solicitud.user.typeId} - {solicitud.user.identification}</td>
                                        
                                        <td>{(solicitud.entity.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{(solicitud.regime.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>     
                                        <td><Moment fromNow ago locale='es'> 
                                        {solicitud.date} 
                                        </Moment>
                                        </td>
                                                          
                                      </tr>                                     
                              
                                        )
                                       }
                                      )}                     
                                                  
                                </tbody>                            
                                
                              </table>                            

                                    <span>{this.state.pager_infopendiente}</span>
                                    
                                    <nav className="nav">
                                    
                                        <ul className="pagination">
                                            {this.state.Pager_Pendientes.map(page_pendien=>{
                                            
                                              var activo=page_pendien.activo
                                              if(activo==true){
                                                activo='page-item active'
                                              }else{
                                                activo='page-item'
                                              }
                                             // alert(activo)
                                              
                                             return(                          
                                                      
                                                     <li className={activo}>
                                                       <a className="nav-link" onClick={()=>{this.loadMoreDatapendiente(page_pendien)}}>{page_pendien.texto}</a>
                                                     </li>                                            
                                               )
                                              }
                                             )
                                            }
                                        </ul>
                                              
                                    </nav>                           
                           
                          </div>

                          <div className="d-flex justify-content-center">                              
                               <img id="empty_page" src={emptypage} alt="logo"  width= "200"/> 
                                                  
                          </div>
                          <div className="d-flex justify-content-center">                  
                               <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                          </div>

                        
              
                      </div>
                      
                      <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                        
                          <div class="d-flex justify-content-center">
                                
                              {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                              
                          </div>
                          
                          <div className="card table-responsive" id="tabla2">                    

                                <div className="row">
                                      
                                    <div className="col-sm-3">
                                          <div className="input-group input-group-sm mb-3 mr-sm-2">
                                             <div className="input-group-prepend">
                                               <div className="input-group-text"><Buscar/></div>
                                             </div>
                                             <input type="search" className="form-control" placeholder="Buscar" name="buscar_aceptadas" onChange={this.BuscarAceptada}/>
                                          </div>                                
                                    </div>
                                    
                                </div>                
                              
                                  <table id="tabla_soli_acepta" className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Fecha solicitud</th>
                                        <th>Paciente</th>                                    
                                        <th>Identificación</th>
                                        <th>EPS</th>
                                        <th>Regimen</th>
                                        <th>Fecha Respuesta</th>
                                        <th>Observación</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                              
                                    {this.state.Aprobadas.map(aprobada=>{
                                      return(      
                                      
                                        <tr>          
                                        <td>{aprobada.id}</td>
                                        <td> 
                                            <Moment format="YYYY/MM/DD HH:mm">
                                              {aprobada.date}
                                            </Moment>   
                                        </td>
                                        <td>{(aprobada.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(aprobada.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(aprobada.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(aprobada.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{aprobada.user.typeId} - {aprobada.user.identification}</td>
                                        <td>{(aprobada.entity.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{(aprobada.regime.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td> 
                                        <td> 
                                            <Moment format="YYYY/MM/DD HH:mm">
                                              {aprobada.approvalDate}
                                            </Moment>   
                                        </td> 
                                        <td>{aprobada.annotation}</td>   
                                                 
                                      </tr>
                              
                                        )
                                       }
                                      )}
                                      
                                                      
                                    </tbody>
                                    
                                  </table>

                                  <span>{this.state.pager_infoaprobada}</span>
                                  <nav className="nav">
                                  
                                      <ul className="pagination">

                                          {this.state.Pager_Aprobadas.map(page_aprobada=>{
                                          
                                            var activo=page_aprobada.activo
                                            if(activo==true){
                                              activo='page-item active'
                                            }else{
                                              activo='page-item'
                                            }

                                           // alert(activo)
                                            
                                           return(                          
                                                    
                                                   <li className={activo}>
                                                     <a className="nav-link" onClick={()=>{this.loadMoreDataaprobada(page_aprobada)}}>{page_aprobada.texto}</a>
                                                   </li>                                            
                                             )
                                            }
                                           )
                                          }

                                      </ul>
                                            
                                  </nav>                     
                                
                          </div>

                          <div className="d-flex justify-content-center">                              
                               <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                             
                          </div>

                          <div className="d-flex justify-content-center">                  
                               <h5 id="text_page2"> <b>No hay información que mostrar</b> </h5>                          
                          </div>
                          
                  
                      </div>
    
                      <div className="tab-pane fade" id="custom-tabs-three-messages" role="tabpanel" aria-labelledby="custom-tabs-three-messages-tab">
                        
                          <div class="d-flex justify-content-center">
                                
                              {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                              
                              
                          </div>
                          
                          <div className="card table-responsive" id="tabla3">                 

                              <div className="row">
                                      
                                <div className="col-sm-3">
                                          <div className="input-group input-group-sm mb-3 mr-sm-2">
                                             <div className="input-group-prepend">
                                               <div className="input-group-text"><Buscar/></div>
                                             </div>
                                             <input type="search" className="form-control" placeholder="Buscar" name="buscar_rechazadas" onChange={this.BuscarRechazada}/>
                                          </div>                                
                                    </div>
                                    
                                </div>
                    
                               
                                  <table id="tabla_soli_rechaza" className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Fecha solicitud</th>
                                        <th>Paciente</th>                                    
                                        <th>Identificación</th>
                                        <th>EPS</th>   
                                        <th>Estado</th>                              
                                        <th>Observación</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                              
                                    {this.state.Rechazadas.map(rechazada=>{
                                      return(      
                                      
                                        <tr>          
                                        <td>{rechazada.id}</td>
                                        <td> 
                                            <Moment format="YYYY/MM/DD HH:mm">
                                              {rechazada.date}
                                            </Moment>   
                                        </td>
                                        <td>{(rechazada.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(rechazada.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(rechazada.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} {(rechazada.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{rechazada.user.typeId} - {rechazada.user.identification}</td>
                                        <td>{(rechazada.entity.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                        
                                        <td>{(rechazada.state).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{rechazada.annotation}</td>  
                                                 
                                      </tr>
                              
                                        )
                                       }
                                      )}
                                                     
                                    </tbody>
                                  </table>

                                  <span>{this.state.pager_inforechaza}</span>
                                  <nav className="nav">
                                  
                                      <ul className="pagination">

                                          {this.state.Pager_Rechazadas.map(page_rechaza=>{
                                          
                                            var activo=page_rechaza.activo
                                            if(activo==true){
                                              activo='page-item active'
                                            }else{
                                              activo='page-item'
                                            }

                                           // alert(activo)
                                            
                                           return(                          
                                                    
                                                   <li className={activo}>
                                                     <a className="nav-link" onClick={()=>{this.loadMoreDatarechazada(page_rechaza)}}>{page_rechaza.texto}</a>
                                                   </li>                                            
                                             )
                                            }
                                           )
                                          }

                                      </ul>
                                            
                                  </nav> 
                                
                          </div>
                          
                          <div className="d-flex justify-content-center">                              
                               <img id="empty_page3" src={emptypage} alt="logo"  width= "200"/>                             
                          </div>

                          <div className="d-flex justify-content-center">                  
                               <h5 id="text_page3"> <b>No hay información que mostrar</b> </h5>                          
                          </div>
              
                      </div>
                     
                    </div>
    
                  </div>
                  {/* /.card */}
                </div>
            
          </div>



          <div className="modal fade" id="modal-solicitudes">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Ingresar paciente</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-6">
                              <div className="form-group">                    
                                      <label >Tipo ID:</label>
                                      <TipoID handleridtipoid={this.handleridtipoid}/>                                                  
                              </div>
          
                          </div>
                          <div className="col-md-6">
          
                              <div className="form-group">                    
                                      <label >Identificación:</label>
                                      <input type="text" maxLength="20" className="form-control" id="id_pacie"  placeholder="Identificación" name="id_pacie" onChange={this.handleChange} onKeyDown={this._handleKeyDown}/>                       
                              </div>
          
                          </div>
                      
                      </div>
                               <div className="form-group">                    
                                   <label >Nombre:</label>
                                   <input type="text" readonly className="form-control" id="dir_sede"  placeholder="Nombre" name="nom_pacien" value={form.nom_pacien} readOnly/>                       
                               </div>
                      
                                      
                         
                               <div className="form-group">                    
                                      <label >EPS:</label>                           
                                      <Eps handlerideps={this.handlerideps}/>                                
                               </div>
          
                      <div className="row">
                                          
                          <div className="col-md-6">
                                <div className="form-group">                    
                                   <label >Regimen:</label>
                                   <Regimen/>
                                </div>
                          
                          </div>
                          <div className="col-md-6">
                  
                                <div className="form-group">                    
                                    <label >Nivel:</label>
                                    <select  className="form-control" id="regimen">
                                       <option  value="1">1</option> 
                                       <option  value="2">2</option>
                                       <option  value="3">3</option>                    
                                    </select>
                                    
                                </div>
                          
                          </div>
                          
                      </div>  
          
          
                </div>
                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-primary">Guardar</button>
                  <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}



          <div className="modal fade" id="modal-respuesta" data-backdrop="static">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Autorización de acceso</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-6">
                              <div className="form-group">                    
                                      <label >ID solicitud:</label>
                                      <input type="text" className="form-control" id="id_soli_acep" name="id_soli_acep" value={form.id_soli_acep} readOnly />                                                  
                              </div>
          
                          </div>
                          <div className="col-md-6">
          
                              <div className="form-group">                    
                                      <label >Identificación:</label>
                                      <input type="text" maxLength="20" className="form-control" id="id_pacie" name="id_pacie_acep" value={form.id_pacie_acep} readOnly />                       
                              </div>
          
                          </div>
                      
                      </div>


                      <div className="form-group">                    
                           <label >Nombre:</label>
                           <input type="text" className="form-control" id="dir_sede"  placeholder="Nombre" name="nom_pcte_acep" value={form.nom_pcte_acep} readOnly/>                            
                           {/*<button id="btn-recoveripass" onClick={()=> this.modal_informacionpcte()}>Más información del paciente</button>  */} 
                           <a id="btn-registrarse" href="#" onClick={()=> this.modal_informacionpcte()} > Más información del paciente </a>                
                      </div>
        
  
                      <div className="form-group">                    
                        <label >Respuesta:</label>
                        <select onChange={this.GetAcepta}  className="form-control" id="acepta" name="acepta">
          
                          <option  value="null">Seleccione...</option>
                          <option  value="true">Aceptar</option>
                          <option  value="false">Rechazar</option>                           
                        
                        </select>                      
                      </div> 
                            
                  
                      <div className="form-group">                    
                              <label >Observación:</label>                           
                              <textarea className="form-control" maxLength="140" rows="2" name="descrip" value={form.descrip} onChange={this.handleChange}  ></textarea>
                              <p>*Si autorizas el acceso el paciente podrá consultar durante 24 horas.</p>                               
                      </div>                    
          
                          
                </div> 

                <div className="modal-footer justify-content-between">      
                                    
                      <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Eventaceptar()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Aceptar</button>           
                
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_btnhis} onClick={()=> this.Historial_Solicitudes()}> {this.state.loading_btnhis?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Historial de solicitudes 
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" fill="currentColor" class="bi bi-card-list" viewBox="-1 0 17 17">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                          </svg>
                      </button>

                      <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
          
                         
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          <div className="modal fade" id="modal-infopcte" data-backdrop="static">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Más información del paciente</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
                                      
                          <div className="col-md-3">
                              <div className="form-group">                    
                                      <label >Fecha de nacimiento:</label>
                                      <input type="text" className="form-control" id="fecha_naci" name="fecha_naci" value={form.fecha_naci} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-2">
                              <div className="form-group">                    
                                      <label >Edad:</label>
                                      <input type="text" className="form-control" id="edad_pcte" name="edad_pcte" value={form.edad_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-1">
          
                              <div className="form-group">                    
                                      <label >Género:</label>
                                      <input type="text"  className="form-control" id="genero_pcte" name="genero_pcte" value={form.genero_pcte} readOnly/>                       
                              </div>
          
                          </div>

                          <div className="col-md-3">
                              <div className="form-group">                    
                                      <label >Teléfono:</label>
                                      <input type="text" className="form-control" id="tel_pcte" name="tel_pcte" value={form.tel_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-3">
          
                              <div className="form-group">                    
                                      <label >Dirección:</label>
                                      <input type="text"  className="form-control" id="dir_pcte" name="dir_pcte" value={form.dir_pcte} readOnly/>                       
                              </div>
          
                          </div>
                      
                      </div>

                      <div className="row">

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Municipio:</label>
                                      <input type="text" className="form-control" id="municipio_pcte" name="municipio_pcte" value={form.municipio_pcte} readOnly/>                                                                         
                              </div>

                          </div>

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Departamento:</label>
                                      <input type="text" className="form-control" id="departamento_pct" name="departamento_pct" value={form.departamento_pct} readOnly/>                                                                         
                              </div>
          
                          </div>
                                      
                          
                      
                      </div>

                      <div className="row">
                                      
                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >EPS:</label>
                                      <input type="text" className="form-control" id="eps_pcte" name="eps_pcte" value={form.eps_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Régimen:</label>
                                      <input type="text" className="form-control" id="regimen_pcte" name="regimen_pcte" value={form.regimen_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>
                         
                      
                      </div>  

                      <div className="row">
                                      
                          <div className="col-md-6">

                              <div className="form-group">                    
                                      <label >Persona responsable:</label>
                                      <input type="text" className="form-control" id="responsable_pcte" name="responsable_pcte" value={form.responsable_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-3">

                              <div className="form-group">                    
                                      <label >Teléfono:</label>
                                      <input type="text" className="form-control" id="telresponsable_pcte" name="telresponsable_pcte" value={form.telresponsable_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>

                          <div className="col-md-3">

                              <div className="form-group">                    
                                      <label >Parentesco:</label>
                                      <input type="text" className="form-control" id="parentescoresp_pcte" name="regimeparentescoresp_pcten_pcte" value={form.parentescoresp_pcte} readOnly/>                                                                         
                              </div>
          
                          </div>
                         
                      
                      </div>      
          
          
                </div>
               
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          <div className="modal fade" id="modal-historial_solicitudes" data-backdrop="static">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Historial de solicitudes</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">  


                      <div className="row">
                                  
                                  <div className="col-md-12">
                                     
                                      <div className="card table-responsive">
                               
                                         
                                          <div className="card-body">
                                             <table id="tabla_historial" className="table table-bordered table-striped table-hover">
                                               <thead>
                                                 <tr>
                                                   <th>ID Solicitud</th>   
                                                   <th>Fecha de solicitud</th>                                                  
                                                   <th>Estado</th>  
                                                   <th>Observación</th>                                                                   
                                                 </tr>
                                               </thead>
                                               <tbody>
                                                  {this.state.Historial_solicitudes.map(historial=>{
                  
                                                    return(      
                                                    
                                                    <tr>          
                                                      <td>{historial.id}</td>                                               
                                                      <td>
                                                      <Moment format="YYYY/MM/DD HH:mm">
                                                       {historial.date}
                                                      </Moment>
                                                      </td>
                                                      <td>{(historial.state).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                          
                                                      <td>{historial.annotation}</td>  
                                                    </tr>
                                            
                                                       )
                                                      }
                                                    )
                                                  }                
                                               </tbody>
                                             </table>
                                          </div>

                                         
                                      </div>            
               
                                  </div>

                      </div>                 
      
                        
          
          
                </div>

                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                </div>
              </div>
              
            </div>
            
          </div> 


          <div className="modal fade" id="modal-verificaconvenio" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-12 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                     
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                              Los médicos del prestador no tienen convenios para brindar consultas por urgencia; si el prestador de salud quiere ofrecer este servicio, debe crear los convenios de los médicos relacionando el tipo de servicio de “consulta por urgencia”. &nbsp; <a id="btn-registrarse" href="/convenios">  Ingresa aquí </a> &nbsp;
                              para establecerlos.

                              </div>
                                                  
                          
                          </div>

                          <br/>                      
                             
                              
                    </div> 
    
                   

                  </div>
                  
                </div>
                
          </div>



</div>
         
      )

  }  
  
  
}




export default Home;